import '@fortawesome/fontawesome-free/js/all.js';
import '../styles/app.scss';

import jQuery from 'jquery';
const $ = require('jquery');
global.$ = global.jQuery = $;

import 'jquery-ui';

const bootstrap = require('bootstrap');
